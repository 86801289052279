<template>
  <v-dialog
    v-model="show"
    persistent
    width="800"
  >
    <v-card
      :loading="loading"
      class="text-left"
      max-width="800"
    >
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="pub.name"
              label="Store name"
              name="name"
              type="text"
              maxlength="32"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="pub.pcode"
              label="Postcode"
              name="pcode"
              type="text"
              maxlength="4"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="pub.logo"
              label="Logo"
              name="logo"
              type="text"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="warning"
          min-width="100px"
          dark
          @click="saveSite(0)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          min-width="100px"
          dark
          @click="saveSite(1)"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
let gResolve;

export default {
    name: 'SiteEdit',
    components: {},
    props: {},
    data() {
        return {
            show: false,
            loading: false,
            siteObj: {},
            pub: {},
            title: ""
        };
    },
    computed: {},
    mounted() {
        console.log( "SiteEdit MOUNTED" );
        // this.pub = Object.assign( {}, this.site.pub );
        // this.siteObj = Object.assign( {}, this.site );
    },
    methods: {
        editSite( site ){
            this.title = "Edit Store";
            this.pub = Object.assign( {}, site.pub );
            this.siteObj = Object.assign( {}, site );
            this.show = true;
            return new Promise( ( resolve ) => {
                gResolve = resolve;
            } );

        },
        saveSite( ok ){
            this.show = false;
            if( !ok && gResolve )return gResolve();

            this.siteObj.pub = this.pub;
            gResolve( this.siteObj );
          
        }

    }
};

</script>

