<template>
  <div
    class="mx-auto text-center pt-4"
    style="max-width: 800px"
  >
    <!-- <Ask ref="ask" /> -->
    <!-- LOGIN -->
    <div v-if="login">
      <v-card
        class="elevation-5"
        :loading="loading"
        :disabled="loading"
      >
        <v-card-text>
          <v-banner
            v-show="!!loginMsg.length"
            icon="mdi-alert"
            outlined
            rounded
            single-line
            sticky
          >
            <span style="color: #FB4F17">{{ loginMsg }}</span> 
          </v-banner>
          <v-form
            ref="form"
          >
            <v-text-field

            
              v-model="email"
              label="email"
              name="email"
              prepend-icon="mdi-email"
              type="text"
              autocomplete="on"
              @keyup.enter="doLogin"
            />

            <v-text-field
              id="pass"
              v-model="pass"
              label="Password"
              name="pass"
              prepend-icon="mdi-lock"
              type="password"
              @keyup.enter="doLogin"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!email || !pass"
            color="primary"
            block
            min-width="100px"
            @click="doLogin"
          >
            Sign in
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- ADMIN CARDS -->
    <div v-else>
      <SiteEdit
        ref="siteRef"
      />
      <v-card
        class="elevation-2"
        :loading="loading"
      >
        <v-toolbar
          color="tccPrimary"
          flat
          dense
        >
          <v-toolbar-title> Admin</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <h1 />

          <v-row>
            <v-col>
              <h2>{{ user.email }}</h2>
            </v-col>
            <v-col>
              <h4>
                ???
              </h4>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="user.first"
                label="Admin settings for Org etc"
                name="first"
                prepend-icon="mdi-account"
                type="text"
                maxlength="32"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="user.loc"
                label="more settings..."
                name="postcode"
                prepend-icon="mdi-map-marker"
                type="text"
                maxlength="4"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            min-width="100px"
            dark
            @click="updateCustomer()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- ORG PICKER -->
      <v-card
        v-if="orgs.length"
        class="elevation-2 mt-8"
        :loading="loading"
      >
        <v-toolbar
          color="tccPrimary"
          flat
          dense
        >
          <v-toolbar-title>SUPER - Select / Create Org</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="org"

                :items="orgs"
                @change="onOrgChange"
              />
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="newOrg"
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                :disabled="!newOrg"
                @click="makeNewOrg()"
              >
                New Org
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- STORE LIST -->
      <v-card
        class="elevation-2 mt-8"
        :loading="loading"
      >
        <v-toolbar
          color="tccBlue"
          flat
          dark
          dense
        >
          <v-toolbar-title>{{ org }} - Store List</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-row
            dense
            no-gutters
          >
            <v-col cols="7">
              <v-text-field
                v-model="filter"
                label="Filter"
                name="search"
                prepend-icon="mdi-filter"
                type="text"
                clearable
              />
            </v-col>
            <v-col cols="3">
              <v-btn @click="newSite()">
                New Store
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div
                v-for="(item,index) in filterStores"
                :key="index"
                class="storeBox"
                @click="editSite(index)"
              >
                {{ item.pub.name }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>


<script>

import SiteEdit from "@/components/SiteEdit.vue";
// import Ask from "@/components/Ask.vue";

export default {
    name: "Admin",
    components: {
        SiteEdit,
        // Ask
    },
    data() {
        return {
            email: "",
            pass: "",
            login: false,
            loading: false,
            user: {},
            orgs: [],
            org: "",
            newOrg: "",
            sites: [],
            filter: "",
            loginMsg: ""


        };
    },
    computed: {
        filterStores() {
            if( !this.filter )return this.sites;
            
            return this.sites.filter( item => {
                return item.pub.name.toLowerCase().indexOf( this.filter.toLowerCase() ) > -1;
            } );
        }
    },
    async mounted() {
        console.log( "Admin MOUNTED" );
        console.log( this.$route );
        if( this.$route.params.id == 'login' ){
            document.title = "The Clearance Club - Admin Login";
            this.login = true;
            return;
        }
        let resp = await this.$http.getUser();
        console.log( resp );

        if( resp.ok ){
            this.user = resp.data.pub;
            this.sites = resp.data.allow;
            this.$emit( 'user', resp.data, resp.token );
            document.title = "The Clearance Club - Admin";
            if( resp.data.role != 'SUPER' )return;
            let res = await this.$http.getUniq( 'org' );
            if( res.ok )this.orgs = res.data;
            console.log( res );
            return;
        }

        document.title = "The Clearance Club - Admin Login";
        this.login = true;

        // if(!resp)
        // this.loading = true;
        // let res = await this.$http.getCustomer();
        // this.loading = false;
        // // console.log( res );
        // if ( res.ok ){
        //     this.customer = res.data;
        //     this.follows = res.data.follows.map( v => v.pub );
        // }
        
        // this.$router.replace('/manage'); //clean up url bar
    },
    methods: {
        async doLogin(){
            let resp = await this.$http.getUser( this.email, this.pass );
            console.log( resp );

            if( resp.ok ){
                this.user = resp.data.pub;
                this.sites = resp.data.allow;
                this.$emit( 'user', resp.data, resp.token );
                this.login = false;
                this.$router.replace( '/admin' ); //clean up url bar
                if( resp.data.role != 'SUPER' )return;
                let res = await this.$http.getUniq( 'org' );
                if( res.ok )this.orgs = res.data;
            }else {
                this.loginMsg = resp.error;
            }

        },
        async onOrgChange( org ){
            console.log( org );
            let res = await this.$http.getSites( org );
            if( res.ok )this.sites = res.data;
        },
        async makeNewOrg(){

            let newMaster = {
                org: this.newOrg,
                site: "master",
                pub: {
                    name: "master",
                    logo: "https://via.placeholder.com/600x100.png?text=LOGO",
                    pcode: "4209",
                    background: "#ffffff",
                    fontColor: "#777"
                }
            };

            let res = await this.$http.updateSite( newMaster );
            console.log( res );
            if( res.ok ){ 
                this.$emit( 'snack', 'New Org created: ' + this.newOrg );
                this.orgs.push( this.newOrg );
            }


        },
        async editSite( i ){
            let siteObj = await this.$refs.siteRef.editSite( this.sites[i] );
            console.log( siteObj );
            if( !siteObj )return; //canceled
            
            let resp = await this.$http.updateSite( siteObj );
            console.log( resp );
            if( resp.ok ){
                this.sites[i] = siteObj;
                this.$forceUpdate();
            }

            // let resp = await this.$http.updateCustomer( this.customer );
            // if( resp.ok )this.$emit( 'snack', "Profile updated" );
            // else this.$emit( "Update failed!", "error" );
        },
        async newSite(){

            let res = await this.$refs.siteRef.editSite( newSiteObj );
            if( !res )return; //canceled
            
            let save = await this.$http.updateSite( res );
            if( save.ok ){
                this.sites.push( res );
                // this.$forceUpdate();
            }

        }


    },
};

let newSiteObj = {
    pub: {
        name: "",
        logo: "",
        pcode: ""
    }
};


</script>

<style scoped>

  .storeBox {
    padding: 8px;
    border: 1px solid #eee;
    margin: 4px;
    text-align: left;
    cursor: pointer;
  }
  .storeBox:hover {
    background-color: #eee;
  }

</style>